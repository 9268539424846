.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

code {
  color: #111111;
  background-color: #AAAAAA;
  padding: 3px;  
}

.project-body {
padding-left: 4px;
}

.project-editor {
  padding-top: 100px!important;
  padding: 40px;
}

.project-item-link {
  padding-right: 8px!important;
}

.project-item-list-title {
  font-style: bold!important;
  font-size: large;
  text-align: left;
  padding-top: 15px;
}

.project-item-list-preamble {
  font-style: italic;
  text-align: left;
  color: #9c9c9c;
  padding-top: 5px;
  padding-bottom: 10px;
}

#project-page-container {
  padding-top: 70px!important;
  padding-left: 20px!important;
}

#services {
  padding-top: 30px;
}

#gallery {
  padding-left: 30px;
  /*display: flex;
  height: 100%;*/
}

#love {
  padding-left: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
